import React from 'react';
import { Button } from 'react-bootstrap';
import '../../Assets/css/home.css';
import { useHistory } from 'react-router-dom';
import EstateDbanner from '../../Assets/images/leander/et3.jpg';
import GolDbanner from '../../Assets/images/we-buy-gold-and-silver-bullion.jpg';

const EstateBanner = () => {
  const history = useHistory();

  return (
    <>
      <div className="rcs_estate_banner">
        <div className="lj_estate_banner_img">
          <img src={EstateDbanner} width="100%" height="100%" alt="estate jewelry buyers near me" />
        </div>
        <div className="rcs_estate_jewelry py-lg-0 py-4">
          <h2>We Buy Estate Jewelry </h2>
          <p className="mb-5">
            Leander Jewelry is a trusted <strong>Estate Jewelry Buyer</strong> passionate about preserving the timeless beauty and rich history of your timeless pieces. Our experienced team understands each piece's sentimental value and craftsmanship, making it more than just jewelry—it's a cherished heirloom. Whether you're looking to sell vintage engagement rings, antique bracelets, or heirloom earrings, we offer fair and competitive prices, ensuring our clients a seamless and rewarding experience.
          </p>
          <Button onClick={() => history.push('/make-an-appointment')}> SCHEDULE AN APPOINTMENT </Button>
        </div>
      </div>
      <div className="rcs_estate_banner wbgold-sb">
        <div className="rcs_estate_jewelry order-lg-1 order-2 py-lg-0 py-4">
          <h2>We Buy Gold and Silver Bullion </h2>
          <p className="mb-4">
            Leander Jewelry is your trusted <strong>Gold & Silver Bullion Buyer</strong>. Whether you're an investor looking to diversify your portfolio or an individual ready to liquidate your bullion holdings, we offer competitive prices and a seamless selling process. At Leander Jewelry, we pride ourselves on transparency, integrity, and expertise in appraising silver and gold bullion. Our knowledgeable team is committed to providing a fair and trustworthy valuation, ensuring you receive the best value for your precious metals.
          </p>
          <ul className="">
            <li>Why Choose Us?</li>
            <li>A team of experienced professionals who can guide you on fair market value.</li>
            <li>Get an honest assessment of your silver and gold bullion's value.</li>
            <li>Competitive rates to ensure a hassle-free experience.</li>
          </ul>
          <Button onClick={() => history.push('/make-an-appointment')}> SCHEDULE AN APPOINTMENT </Button>
        </div>

        <div className="lj_estate_banner_img order-lg-2 order-1">
          <img src={GolDbanner} width="100%" height="100%" alt="We buy Gold and Silver Bullion" />
        </div>
      </div>
    </>
  );
};

export default EstateBanner;
